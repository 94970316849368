import React, { useEffect, useState, useCallback } from "react";
import $http from "../services/api";
import ReactPaginate from "react-paginate";
import { Link } from "react-router-dom";

const FamousMember = () => {
  const [member, setMember] = useState();
  const [pageCount, setPageCount] = useState(0);
  const [page, setPage] = useState(1);

  const handlePageClick = (event) => {
    const page = event.selected + 1;
    setPage(page);
  };

  const getFamousMember = useCallback(() => {
    $http({
      url: `famous/member/list/?page=${page}`,
      method: "GET",
    }).then((res) => {
      setMember(res.data.results);
      setPageCount(Math.ceil(res.data.count / 8));
    });
  }, [page]);

  useEffect(() => {
    getFamousMember();
  }, [getFamousMember]);

  return member ? (
    <section id="team" className="team">
      <div className="container">
        <div className="section-title" data-aos="fade-up">
          <h2>Bitiruvchilar</h2>
          <p>Mashhur bitiruvchilarimiz</p>
        </div>
        <br />
        <br />
        <br />
        <br />
        <br />
        <div className="row ">
          {member.map((m) => (
            <div className="col-lg-3 col-md-6 mb-5" key={m.id}>
              <div className="member" data-aos="zoom-in" data-aos-delay="100">
                <div className="pic">
                  <img
                    src={
                      m.image
                        ? m.image
                        : "https://api.alumni.buxdu.uz/media/members/no-image.png"
                    }
                    style={{ height: 350, maxWidth: 300 }}
                    alt="BuxDu-Buxoro davlat universiteti"
                  />
                </div>
                <div className="member-info">
                  <h4>
                    <Link to={`/famous/member/${m.id}/`}>
                      {m.first_name} {m.last_name} {m.surname}
                    </Link>
                  </h4>
                  <span>
                    {m.special.code}-{m.special.title}
                  </span>
                  <div className="social">
                    <a href={m.telegram}>
                      <i className="bi bi-send"></i>
                    </a>
                    <a href={m.facebook}>
                      <i className="bi bi-facebook"></i>
                    </a>
                    <a href={m.instagram}>
                      <i className="bi bi-instagram"></i>
                    </a>
                    <a href={m.youtube}>
                      <i className="bi bi-youtube"></i>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
        <div className="row">
          <ReactPaginate
            nextLabel=">"
            onPageChange={handlePageClick}
            pageRangeDisplayed={3}
            marginPagesDisplayed={2}
            pageCount={pageCount}
            previousLabel="< "
            pageClassName="page-item"
            pageLinkClassName="page-link"
            previousClassName="page-item"
            previousLinkClassName="page-link"
            nextClassName="page-item"
            nextLinkClassName="page-link"
            breakLabel="..."
            breakClassName="page-item"
            breakLinkClassName="page-link"
            containerClassName="pagination"
            activeClassName="active"
            renderOnZeroPageCount={null}
          />
        </div>
      </div>
    </section>
  ) : (
    <div className="container">
      <div className="flex-row">
        <div
          className="text-center"
          style={{ marginTop: "15rem", marginBottom: "15rem" }}
        >
          <img
            alt="BuxDu-Buxoro davlat universiteti"
            src="https://icons8.com/preloaders/preloaders/30/Fountain.gif"
          />
        </div>
      </div>
    </div>
  );
};

export default FamousMember;
