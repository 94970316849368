import React, { useState } from "react";
import { Link, useLocation } from "react-router-dom";

import "../assets/vendor/aos/aos.css";
import "../assets/vendor/bootstrap/css/bootstrap.min.css";
import "../assets/vendor/bootstrap-icons/bootstrap-icons.css";
import "../assets/vendor/remixicon/remixicon.css";

import "../assets/css/style.css";

const Navbar = () => {
  const location = useLocation();
  const [isMobile, setIsMobile] = useState(false);

  const mobileNav = () => {
    isMobile ? setIsMobile(false) : setIsMobile(true);
  };

  const disableMobileNav = () => {
    setIsMobile(false);
  };

  return (
    <header
      id="header"
      className="fixed-top d-flex align-items-center header-transparent"
    >
      <div className="container d-flex align-items-center justify-content-between">
        <div className="logo">
          <Link to="/">
            <img
              src="https://buxdu.uz/static/images/logo/logo_uz.png"
              alt="Buxoro davlat universiteti"
              className="img-fluid"
            />
          </Link>
        </div>

        <nav
          id="navbar"
          className={isMobile ? "navbar navbar-mobile" : "navbar"}
        >
          <ul>
            <li>
              <Link
                className={
                  location.pathname === "/"
                    ? "nav-link scrollto active"
                    : "nav-link scrollto"
                }
                to={"/"}
                onClick={disableMobileNav}
              >
                Bosh sahifa
              </Link>
            </li>
            <li>
              <Link
                className={
                  location.pathname === "/members/"
                    ? "nav-link scrollto active"
                    : "nav-link scrollto"
                }
                to={"/members/"}
                onClick={disableMobileNav}
              >
                Bitiruvchilar
              </Link>
            </li>
            <li>
              <Link
                className={
                  location.pathname === "/famous/alumni/"
                    ? "nav-link scrollto active"
                    : "nav-link scrollto"
                }
                to={"/famous/alumni/"}
                onClick={disableMobileNav}
              >
                Mashhur bitiruvchilarimiz
              </Link>
            </li>
            <li>
              <Link
                className={
                  location.pathname === "/add/member/"
                    ? "nav-link scrollto active"
                    : "nav-link scrollto"
                }
                to={"/add/member/"}
                onClick={disableMobileNav}
              >
                A'zo bo'lish
              </Link>
            </li>
            <li>
              <Link
                className={
                  location.pathname === "/contact/"
                    ? "nav-link scrollto active"
                    : "nav-link scrollto"
                }
                to={"/contact/"}
                onClick={disableMobileNav}
              >
                Qo'llab-quvvatlash
              </Link>
            </li>
          </ul>
          <i
            className={
              isMobile
                ? "bi bi-x-lg mobile-nav-toggle"
                : "bi bi-list mobile-nav-toggle"
            }
            onClick={mobileNav}
          ></i>
        </nav>
      </div>
    </header>
  );
};

export default Navbar;
