import React from "react";

const css = {
  a: {
    color: "#1acc8d",
  },
};

const Footer = () => {
  return (
    <section id="footer">
      <div className="footer-top">
        <div className="container">
          <div className="row">
            <div className="col-lg-4 col-md-6">
              <div className="footer-info">
                <h3>Bog'lanish</h3>
                <p>
                  Buxoro sh. M.Iqbol ko`chasi 11-uy
                  <br />
                  <br />
                  <strong>Telefon:</strong> (+998) 65 221-29-14
                  <br />
                  <strong>Fax:</strong> 8(365) 221-27-07
                  <br />
                  <strong>Email:</strong> buxdu_rektor@buxdu.uz
                  <br />
                </p>
              </div>
            </div>

            <div className="col-lg-4 col-md-6">
              <div className="footer-info">
                <h3>Rekvizitlar</h3>
                <p>
                  Moliya vazirligi g`aznachiligi
                  <br />
                  <br />
                  <strong>Hisob raqami:</strong> 400910860064017950100079002
                  <br />
                  <strong>MFO:</strong> 00014 <strong>INN:</strong> 201122919
                  <br />
                  Markaziy bank HKKM Toshkent sh.
                  <br />
                  <strong>INN:</strong> 201504275
                  <br />
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="container">
        <div className="copyright">
          &copy; Copyright{" "}
          <strong>
            <a style={css.a} href="https://buxdu.uz">
              buxdu.uz
            </a>
          </strong>
          . All Rights Reserved
        </div>
        <div className="credits">
          Powered by{" "}
          <a style={css.a} href="https://t.me/rajab_murod/">
            RTTM
          </a>
        </div>
      </div>
    </section>
  );
};

export default Footer;
