import React from "react";

const Contact = () => {
  return (
    <section id="contact" className="contact">
      <div className="container">
        <div className="section-title" data-aos="fade-up">
          <h2>Contact</h2>
          <p>Bog'lanish</p>
        </div>
        <br />
        <br />
        <div className="row mt-5">
          <div className="col-lg-4" data-aos-delay="100">
            <div className="info">
              <div className="address">
                <i className="bi bi-geo-alt"></i>
                <h4>Manzil:</h4>
                <p>Buxoro sh. M.Iqbol ko`chasi 11-uy</p>
              </div>

              <div className="email">
                <i className="bi bi-envelope"></i>
                <h4>Email:</h4>
                <p>buxdu_rektor@buxdu.uz</p>
              </div>

              <div className="phone">
                <i className="bi bi-phone"></i>
                <h4>Call:</h4>
                <p>(+998) 65 221-29-14</p>
              </div>
            </div>
          </div>
          <div className="col-lg-4" data-aos-delay="100">
            <div className="info">
              <div className="address">
                <i className="bi bi-credit-card-2-back-fill"></i>
                <h4>Hisob raqami:</h4>
                <p>400910860064017950100079002</p>
              </div>

              <div className="email">
                <i className="bi bi-file-text"></i>
                <h4>INN:</h4>
                <p>201504275</p>
              </div>

              <div className="phone">
                <i className="bi bi-file-text"></i>
                <h4>MFO:</h4>
                <p>00014</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Contact;
