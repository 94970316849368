import React, { useEffect, useState, useCallback } from "react";
import $http from "../services/api";
import { useParams } from "react-router-dom";

const FamousMemberDetail = () => {
  const { id } = useParams();
  const [member, setMember] = useState();

  const getFamousMember = useCallback(() => {
    $http({
      url: `famous/member/${id}/`,
      method: "GET",
    }).then((res) => {
      setMember(res.data);
    });
  }, [id]);

  useEffect(() => {
    getFamousMember();
  }, [getFamousMember]);

  return member ? (
    <section id="team" className="team">
      <div className="container">
        <br />
        <br />
        <div className="row ">
          <div className="col-lg-3 col-md-6 mb-5">
            <div className="member" data-aos="zoom-in" data-aos-delay="100">
              <div className="pic">
                <img
                  src={
                    member.image
                      ? member.image
                      : "https://api.alumni.buxdu.uz/media/members/no-image.png"
                  }
                  style={{ height: 350, maxWidth: 300 }}
                  alt="BuxDu-Buxoro davlat universiteti"
                />
              </div>
              <div className="member-info">
                <h4>
                  {member.first_name} {member.last_name} {member.surname}
                </h4>
                <span>
                  {member.special.code}-{member.special.title}
                </span>
                <div className="social">
                  <a href={member.telegram}>
                    <i className="bi bi-send"></i>
                  </a>
                  <a href={member.facebook}>
                    <i className="bi bi-facebook"></i>
                  </a>
                  <a href={member.instagram}>
                    <i className="bi bi-instagram"></i>
                  </a>
                  <a href={member.youtube}>
                    <i className="bi bi-youtube"></i>
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-9 col-md-6">
            {
              <div
                dangerouslySetInnerHTML={{
                  __html: member.desc,
                }}
              />
            }
          </div>
        </div>
      </div>
    </section>
  ) : (
    <div className="container">
      <div className="flex-row">
        <div
          className="text-center"
          style={{ marginTop: "15rem", marginBottom: "15rem" }}
        >
          <img
            alt="BuxDu-Buxoro davlat universiteti"
            src="https://icons8.com/preloaders/preloaders/30/Fountain.gif"
          />
        </div>
      </div>
    </div>
  );
};

export default FamousMemberDetail;
