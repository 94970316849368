import React, { useCallback, useEffect, useState } from "react";
import MemberList from "./MemberList";
import $http from "../services/api";
import ReactPaginate from "react-paginate";

const SearchForm = (props) => {
  const initialFormState = {
    first_name: "",
    last_name: "",
    surname: "",
    year: "",
    special: "",
  };

  const [member, setMember] = useState(initialFormState);
  const [filteredmember, setFilteredMember] = useState();
  const [pageCount, setPageCount] = useState(0);
  const [page, setPage] = useState(1);
  const [ischange, setIsChange] = useState(false);

  const handleOnClick = () => {
    setIsChange(true);
  };

  const handlePageClick = (event) => {
    const page = event.selected + 1;
    setPage(page);
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setMember({ ...member, [name]: value });
  };

  const resetForm = () => {
    setMember(initialFormState);
    setIsChange(false);
  };

  const searchFunc = useCallback(() => {
    $http({
      url: `member/list/?page=${page}&first_name__startswith=${member.first_name}&last_name__startswith=${member.last_name}&surname__startswith=${member.surname}&year__title=${member.year}&special=${member.special}`,
      method: "GET",
    }).then((res) => {
      setFilteredMember(res.data.results);
      setPageCount(Math.ceil(res.data.count / 10));
    });
  }, [member, page]);

  useEffect(() => {
    searchFunc();
  }, [searchFunc, page, member]);

  return (
    <div className="container">
      <form onReset={resetForm} className="form-group mb-4">
        <div className="row">
          <div className="col-md-2">
            <div className="form-group">
              <input
                type="text"
                className="form-control"
                name="first_name"
                value={member.first_name}
                onChange={handleInputChange}
                placeholder="Familiya"
              />
            </div>
          </div>
          <div className="col-md-2">
            <div className="form-group">
              <input
                type="text"
                className="form-control"
                name="last_name"
                value={member.last_name}
                onChange={handleInputChange}
                placeholder="Ism"
              />
            </div>
          </div>
          <div className="col-md-2">
            <div className="form-group">
              <input
                type="text"
                className="form-control"
                name="surname"
                value={member.surname}
                onChange={handleInputChange}
                placeholder="Otasi"
              />
            </div>
          </div>

          <div className="col-md-2">
            <div className="form-group">
              <input
                type="number"
                className="form-control"
                name="year"
                value={member.year}
                onChange={handleInputChange}
                placeholder="yili"
              />
            </div>
          </div>
          <div className="col-md-3">
            <div className="form-group">
              <select
                name="special"
                className="form-control"
                onChange={handleInputChange}
                onClick={handleOnClick}
              >
                {ischange ? (
                  <option disabled="disabled">
                    --- Mutaxassisligi tanglang ---
                  </option>
                ) : (
                  <option>--- Mutaxassisligi tanglang ---</option>
                )}
                {props.special ? (
                  props.special.map((c) => (
                    <option key={c.id} value={c.id}>
                      {c.code}-{c.title}
                    </option>
                  ))
                ) : (
                  <option>---Mutaxassisligi tanglang---</option>
                )}
              </select>
            </div>
          </div>
          <div className="col-md-1">
            <div className="form-group">
              <button type="reset" className="btn btn-danger">
                <i className="ri-delete-bin-2-fill"></i>
              </button>
            </div>
          </div>
        </div>
      </form>

      <MemberList members={filteredmember} />
      <br />
      <ReactPaginate
        nextLabel=">"
        onPageChange={handlePageClick}
        pageRangeDisplayed={3}
        marginPagesDisplayed={2}
        pageCount={pageCount}
        previousLabel="< "
        pageClassName="page-item"
        pageLinkClassName="page-link"
        previousClassName="page-item"
        previousLinkClassName="page-link"
        nextClassName="page-item"
        nextLinkClassName="page-link"
        breakLabel="..."
        breakClassName="page-item"
        breakLinkClassName="page-link"
        containerClassName="pagination"
        activeClassName="active"
        renderOnZeroPageCount={null}
      />
    </div>
  );
};

export default SearchForm;
