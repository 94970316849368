import React, { useEffect, useState } from "react";
import $http from "../services/api";
import SearchForm from "./SearchForm";

const SearchMember = () => {
  const [specials, setSpecial] = useState();

  const getSpecialList = () => {
    $http({ url: "special/list/", method: "GET" }).then((res) => {
      setSpecial(res.data);
    });
  };

  useEffect(() => {
    getSpecialList();
  }, []);

  return specials ? (
    <section>
      <div className="container mt-5">
        <div className="flex-row">
          <div className="flex-large">
            <h2 className="mt-4 mb-4">Bitiruvchini qidirish</h2>
            <SearchForm special={specials} />
          </div>
        </div>
      </div>
    </section>
  ) : (
    <section>
      <div className="container mt-5">
        <div className="flex-row">
          <div
            className="text-center"
            style={{ marginTop: "5rem", marginBottom: "5rem" }}
          >
            <img
              alt="BuxDu-Buxoro davlat universiteti"
              src="https://icons8.com/preloaders/preloaders/30/Fountain.gif"
            />
          </div>
        </div>
      </div>
    </section>
  );
};

export default SearchMember;
