import React from "react";

import About from "../views/About";
import Feature from "../views/Feature";

const HomePage = () => {
  return (
    <main id="main">
      <About />
      <Feature />
    </main>
  );
};

export default HomePage;
