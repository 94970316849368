import React from "react";
import { Link } from "react-router-dom";

const Feature = () => {
  return (
    <section id="features" className="features" style={{ paddingTop: 0 }}>
      <div className="container">
        <div className="section-title mb-5" data-aos="fade-up">
          <h2>Biz bilan bo'ling</h2>
          <p>Birgalikda rivojlanamiz</p>
        </div>

        <div className="row">
          <div className="col-lg-4 col-md-4 mt-4">
            <div className="icon-box" data-aos="zoom-in" data-aos-delay="400">
              <i className="ri-file-edit-line" style={{ color: "#4233ff" }}></i>
              <h3>
                <Link to="">Rektorning bitiruvchilarga murojaati</Link>
              </h3>
            </div>
          </div>
          <div className="col-lg-4 col-md-4 mt-4">
            <div className="icon-box" data-aos="zoom-in" data-aos-delay="450">
              <i className="ri-group-line" style={{ color: "#b2904f" }}></i>
              <h3>
                <Link to="">Bitiruvchilar klubi haqida</Link>
              </h3>
            </div>
          </div>
          <div className="col-lg-4 col-md-4 mt-4">
            <div className="icon-box" data-aos="zoom-in" data-aos-delay="500">
              <i className="ri-user-add-line" style={{ color: "#b20969" }}></i>
              <h3>
                <Link to="/add/member/">
                  Bitiruvchilar klubiga a'zo bo'lish
                </Link>
              </h3>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Feature;
