import React from "react";

const MemberList = (props) => {
  return props.members ? (
    <table className="table table-bordered">
      <thead>
        <tr>
          <th>Familiya</th>
          <th>Ism</th>
          <th>Otasi</th>
          <th>Yil</th>
          <th>Mutaxassislik</th>
        </tr>
      </thead>
      <tbody>
        {props.members.map((member) => (
          <tr key={member.id}>
            <td>{member.first_name}</td>

            <td>{member.last_name}</td>
            <td>{member.surname}</td>
            <td>{member.year.title}</td>
            <td>{member.special.title}</td>
          </tr>
        ))}
      </tbody>
    </table>
  ) : (
    <div className="container">
      <div className="flex-row">
        <div
          className="text-center"
          style={{ marginTop: "5rem", marginBottom: "5rem" }}
        >
          <img
            alt="BuxDu-Buxoro davlat universiteti"
            src="https://icons8.com/preloaders/preloaders/30/Fountain.gif"
          />
        </div>
      </div>
    </div>
  );
};

export default MemberList;
