import React, { useState, useEffect } from "react";
import $http from "../services/api";
import { useNavigate } from "react-router-dom";

const CreateMember = () => {
  const initialFormState = {
    first_name: "",
    last_name: "",
    surname: "",
    year: "",
    special: "",
    education_type: "",
    country: "",
    city: "",
    position: "",
    academic_degree: "",
    phone_number: "",
    email: "",
    telegram: "",
    facebook: "",
    youtube: "",
    instagram: "",
  };

  const [newmember, setNewMember] = useState(initialFormState);
  const [year, setYear] = useState();
  const [special, setSpecial] = useState();
  const [ischange, setIsChange] = useState(false);
  const [border, setBorder] = useState("");
  const navigate = useNavigate();

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setNewMember({ ...newmember, [name]: value });
  };

  const handleOnClick = () => {
    setIsChange(true);
  };

  const onSubmitForm = () => {
    $http({ url: `member/create/`, method: "POST", data: newmember }).then(
      (res) => {
        console.log(res.status);
        alert("Ma`lumotlar jo`natildi.");
        navigate("/");
      }
    );
  };

  const getYear = () => {
    $http({ url: "year/list/", method: "GET" }).then((res) => {
      setYear(res.data);
    });
  };

  const getSpecial = () => {
    $http({ url: "special/list/", method: "GET" }).then((res) => {
      setSpecial(res.data);
    });
  };

  useEffect(() => {
    getYear();
    getSpecial();
  }, []);

  return (
    <section id="contact" className="contact">
      <div className="container">
        <div className="section-title" data-aos="fade-up">
          <h2>Biz bilan bo'ling</h2>
          <p>Bitiruvchilar klubiga a'zo bo'lish</p>
        </div>
        <br />
        <br />
        <br />
        <div className="row">
          <div className="col-lg-8 mt-5 mt-lg-0" data-aos-delay="200">
            <form
              onSubmit={(e) => {
                e.preventDefault();
                if (
                  newmember.year &&
                  newmember.special &&
                  newmember.education_type
                ) {
                  onSubmitForm();
                } else {
                  setBorder("red");
                  return;
                }
              }}
              className="php-email-form"
            >
              <div className="row">
                <div className="col-md-4 form-group">
                  <input
                    type="text"
                    name="first_name"
                    className="form-control"
                    placeholder="Familiya *"
                    required
                    onChange={handleInputChange}
                    value={newmember.first_name}
                  />
                </div>
                <div className="col-md-4 form-group mt-3 mt-md-0">
                  <input
                    type="text"
                    className="form-control"
                    name="last_name"
                    placeholder="Ism *"
                    required
                    onChange={handleInputChange}
                    value={newmember.last_name}
                  />
                </div>
                <div className="col-md-4 form-group mt-3 mt-md-0">
                  <input
                    type="text"
                    className="form-control"
                    name="surname"
                    placeholder="Otasi *"
                    required
                    onChange={handleInputChange}
                    value={newmember.surname}
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-md-4 form-group">
                  <select
                    className="form-control"
                    name="year"
                    required
                    onChange={handleInputChange}
                    onClick={handleOnClick}
                    style={{
                      borderColor: !newmember.year ? border : "green",
                    }}
                  >
                    {ischange ? (
                      <option disabled="disabled">---Tugatgan yili *---</option>
                    ) : (
                      <option>---Tugatgan yili *---</option>
                    )}
                    {year ? (
                      year.map((y) => (
                        <option key={y.id} value={y.id}>
                          {y.title}
                        </option>
                      ))
                    ) : (
                      <option>---Tugatgan yili *---</option>
                    )}
                  </select>
                </div>
                <div className="col-md-4 form-group">
                  <select
                    name="special"
                    onChange={handleInputChange}
                    required
                    className="form-control"
                    onClick={handleOnClick}
                    style={{
                      borderColor: !newmember.special ? border : "",
                    }}
                  >
                    {ischange ? (
                      <option disabled="disabled">
                        ---Mutaxassisligi *---
                      </option>
                    ) : (
                      <option>---Mutaxassisligi *---</option>
                    )}
                    {special ? (
                      special.map((s) => (
                        <option key={s.id} value={s.id}>
                          {s.code}-{s.title}
                        </option>
                      ))
                    ) : (
                      <option>---Mutaxassisligi *---</option>
                    )}
                  </select>
                </div>
                <div className="col-md-4 form-group">
                  <select
                    name="education_type"
                    required
                    className="form-control"
                    onChange={handleInputChange}
                    onClick={handleOnClick}
                    style={{
                      borderColor: !newmember.education_type ? border : "",
                    }}
                  >
                    {ischange ? (
                      <option disabled="disabled">---Ta'lim turi *---</option>
                    ) : (
                      <option>---Ta'lim turi *---</option>
                    )}

                    <option value={"bakalavr"}>Bakalavr</option>
                    <option value={"magistr"}>Magistr</option>
                    <option value={"sirtqi"}>Sirtqi</option>
                    <option value={"kechki"}>Kechki</option>
                  </select>
                </div>
              </div>
              <div className="row">
                <div className="col-md-4 form-group">
                  <input
                    type="text"
                    name="country"
                    className="form-control"
                    placeholder="Davlat"
                    onChange={handleInputChange}
                    value={newmember.country}
                  />
                </div>
                <div className="col-md-4 form-group mt-3 mt-md-0">
                  <input
                    type="text"
                    className="form-control"
                    name="city"
                    placeholder="Shahar/Tuman"
                    onChange={handleInputChange}
                    value={newmember.city}
                  />
                </div>
                <div className="col-md-4 form-group mt-3 mt-md-0">
                  <input
                    type="text"
                    className="form-control"
                    name="position"
                    placeholder="Ish joyi, lavozim"
                    onChange={handleInputChange}
                    value={newmember.position}
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-md-4 form-group">
                  <input
                    type="text"
                    name="academic_degree"
                    className="form-control"
                    placeholder="Ilmiy unvoni"
                    onChange={handleInputChange}
                    value={newmember.academic_degree}
                  />
                </div>
                <div className="col-md-4 form-group mt-3 mt-md-0">
                  <input
                    type="text"
                    className="form-control"
                    name="phone_number"
                    placeholder="Telefon *"
                    required
                    onChange={handleInputChange}
                    value={newmember.phone_number}
                  />
                </div>
                <div className="col-md-4 form-group mt-3 mt-md-0">
                  <input
                    type="email"
                    className="form-control"
                    name="email"
                    placeholder="E-mail *"
                    required
                    onChange={handleInputChange}
                    value={newmember.email}
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-md-3 form-group">
                  <input
                    type="text"
                    name="telegram"
                    className="form-control"
                    placeholder="Telegram account"
                    onChange={handleInputChange}
                    value={newmember.telegram}
                  />
                </div>
                <div className="col-md-3 form-group mt-3 mt-md-0">
                  <input
                    type="text"
                    className="form-control"
                    name="facebook"
                    placeholder="Facebook account"
                    onChange={handleInputChange}
                    value={newmember.facebook}
                  />
                </div>
                <div className="col-md-3 form-group mt-3 mt-md-0">
                  <input
                    type="text"
                    className="form-control"
                    name="instagram"
                    placeholder="Instagram account"
                    onChange={handleInputChange}
                    value={newmember.instagram}
                  />
                </div>
                <div className="col-md-3 form-group mt-3 mt-md-0">
                  <input
                    type="text"
                    className="form-control"
                    name="youtube"
                    placeholder="Youtube account"
                    onChange={handleInputChange}
                    value={newmember.youtube}
                  />
                </div>
              </div>

              <div className="text-right">
                <button style={{ borderRadius: 0 }} type="submit">
                  Jo'natish
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </section>
  );
};

export default CreateMember;
