import React from "react";

import "../assets/vendor/aos/aos.css";
import "../assets/vendor/bootstrap/css/bootstrap.min.css";
import "../assets/vendor/bootstrap-icons/bootstrap-icons.css";
import "../assets/vendor/remixicon/remixicon.css";

import "../assets/css/style.css";

import Contact from "../views/Contact";

const ContactPage = () => {
  return (
    <main id="main">
      <Contact />
    </main>
  );
};

export default ContactPage;
