import React, { useEffect, useState } from "react";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Pie } from "react-chartjs-2";
import $http from "../services/api";

const About = () => {
  ChartJS.register(ArcElement, Tooltip, Legend);
  const [stats, setStats] = useState();

  const getStats = () => {
    $http({ url: "member/stats/", method: "GET" }).then((res) => {
      setStats(res.data);
    });
  };

  useEffect(() => {
    getStats();
  }, []);

  return stats ? (
    <section id="about" className="about mt-5">
      <div className="container">
        <div className="row">
          <div className="col-xl-5 col-lg-6 video-box d-flex justify-content-center align-items-stretch"></div>

          <div className="col-xl-7 col-lg-6 icon-boxes d-flex flex-column align-items-stretch justify-content-center py-5 px-lg-5">
            <h3>Bitiruvchilar klubi</h3>

            <p>
              Bitiruvchilar klubi - bu universitet hayotida ishtirok etadigan
              odamlar jamoasi.
            </p>
            <p>
              Bu turli avlod bitiruvchilarini birlashtirish, ijtimoiy ahamiyat
              va e'tirofni oshirish uchun mo'ljallangan universitet, miting
              bitiruvchilari.
            </p>
            <p>
              Bizning loyiha va dasturlarimiz o'qishni tugatgandan so'ng
              universitet bilan aloqada bo'lish imkoniyatidir.
            </p>
            <div className="row">
              <div className="col-md-7">
                <Pie
                  data={{
                    labels: ["Mashhur bitiruvchilar", "Bitiruvchilar"],
                    datasets: [
                      {
                        label: "# of Votes",
                        data: [stats.famous, stats.total - stats.famous],
                        backgroundColor: [
                          "rgba(255, 99, 132, 0.2)",
                          "rgba(54, 162, 235, 0.2)",
                        ],
                        borderColor: [
                          "rgba(255, 99, 132, 1)",
                          "rgba(54, 162, 235, 1)",
                        ],
                        borderWidth: 1,
                      },
                    ],
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  ) : (
    <div className="container">
      <div className="flex-row">
        <div
          className="text-center"
          style={{ marginTop: "5rem", marginBottom: "5rem" }}
        >
          <img
            alt="BuxDu-Buxoro davlat universiteti"
            src="https://icons8.com/preloaders/preloaders/30/Fountain.gif"
          />
        </div>
      </div>
    </div>
  );
};

export default About;
