import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import HomePage from "./components/HomePage";
import Navbar from "./components/Navbar";
import Footer from "./components/Footer";
import SearchMember from "./components/SearchMember";
import ContactPage from "./components/ContactPage";
import FamousMember from "./components/FamousMember";
import CreateMember from "./components/CreateMember";
import FamousMemberDetail from "./components/FamousMemberDetail";

const App = () => {
  return (
    <BrowserRouter>
      <Navbar />
      <Routes>
        <Route path="/" name="Home" element={<HomePage />} />
        <Route path="/contact/" name="Contact" element={<ContactPage />} />
        <Route path="/members/" name="MemberList" element={<SearchMember />} />
        <Route
          path="/add/member/"
          name="CreateMember"
          element={<CreateMember />}
        />
        <Route
          path="famous/alumni/"
          name="FamousMember"
          element={<FamousMember />}
        />
        <Route
          path="/famous/member/:id/"
          name="Famous Member Detail"
          element={<FamousMemberDetail />}
        />
      </Routes>
      <Footer />
    </BrowserRouter>
  );
};

export default App;
